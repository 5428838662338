@import "01-Settings/_settings.main";

@import "02-Tools/_tools.breakpoints";
@import "02-Tools/_tools.rem-calculator";

@import "03-Components/common/_components.header";
@import "03-Components/common/_components.footer";
@import "03-Components/common/atoms/_components.buttons";
@import "03-Components/common/atoms/_components.upcoming";
@import "03-Components/_components.home";
@import "03-Components/_components.about";
@import "03-Components/_components.features";
@import "03-Components/_components.contactus";
@import "03-Components/_components.faq";
@import "03-Components/_components.terms";

body {
    margin: 0;
}