.c-faq {
    align-items: center;
    background-color: $backgrond-color;
    display: flex;
    flex-direction: column;
    font-family: $secondary-font;
    text-align: center;
    padding-bottom: 30px;

    h1 {
        font-size: 48px;
        font-weight: 800;
        line-height: 64px;
        letter-spacing: 0em;
    }

    h3 {
        text-align: left;
    }

    a {
        color: $brand-blue;
        text-decoration: none;
    }

    &__open {
        cursor: pointer;
        margin-right: rem(14);
    }

    &__close {
        cursor: pointer;
        display: none;
        margin-right: rem(14);
    }

    &__question {
        background-color: $light-blue;
        border-radius: 5px;
        display: flex;
        font-size: 14px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0em;
        margin-bottom: rem(17);
        text-align: left;

        justify-content: space-between;
        padding: rem(14) 0 rem(14) rem(16);
        text-align: left;
        width: rem(776);

        @include max-800 {
            width: rem(419);
        }

        @include max-500 {
            width: rem(315);
        }

        @include max-320 {
            font-size: 10px;
            width: rem(250);
        }

        &--container {
            align-items: center;
            display: flex;
            flex-direction: column;
        }
    }

    &__answer {
        display: none;
        font-size: 16px;
        font-weight: 400;
        line-height: 32px;
        letter-spacing: 0em;
        margin: rem(28) 0 rem(38);
        text-align: left;
        width: rem(702);

        @include max-800 {
            width: rem(422);
        }

        @include max-500 {
            width: rem(318);
        }

        @include max-320 {
            width: rem(253);
        }
    }

    &__second-section {
        border-radius: 16px;
        position: relative;
        height: rem(300);
        margin-top: rem(118);
        min-width: rem(1015);
        text-align: left;

        @include max-1050 {
            min-width: rem(900);
        }

        @include max-1000 {
            min-width: rem(920);
        }

        @include max-930 {
            min-width: rem(790);
        }

        @include max-800 {
            min-width: rem(690);
        }

        @include max-700 {
            min-width: rem(590);
        }

        @include max-600 {
            min-width: rem(490);
        }

        @include max-500 {
            min-width: rem(390);
        }

        @include max-400 {
            margin-top: rem(25);
            min-width: rem(318);
        }

        @include max-320 {
            min-width: rem(253);
        }

        h1 {
            color: $white;
            position: relative;
            font-family: $secondary-font;
            font-size: 40px;
            font-weight: 800;
            line-height: 54px;
            margin: 0 0 0 rem(80);
            padding-top: rem(69);
            letter-spacing: 0em;

            @include max-800 {
                margin-left: rem(20);
            }

            @include max-500 {
                font-size: 30px;
            }

            @include max-400 {
                font-size: 25px;
                padding-top: rem(49);
            }

            @include max-320 {
                font-size: 20px;
                padding-top: rem(29);
            }
        }

        &__vector1 {
            background-color: $brand-darker;
            border-radius: 16px;
            left: 0;
            position: absolute;

            @include max-1050 {
                width: rem(900);
            }

            @include max-1000 {
                width: rem(920);
            }

            @include max-930 {
                width: rem(790);
            }

            @include max-800 {
                height: 240px;
                width: rem(690);
            }

            @include max-700 {
                width: rem(590);
            }

            @include max-600 {
                width: rem(490);
            }

            @include max-500 {
                width: rem(390);
            }

            @include max-400 {
                width: rem(318);
            }

            @include max-320 {
                height: 210px;
                width: rem(253);
            }
        }

        &__input-group {
            height: rem(52);
            margin: rem(32) 0 0 rem(80);
            position: relative;

            @include max-800 {
                margin-left: rem(20);
            }

            input {
                background: #F4F5F7;
                border: none;
                border-radius: 5px 0px 0px 5px;
                height: 100%;
                padding-left: 1rem;
                width: rem(232);

                @include max-500 {
                    width: rem(150);
                }

                @include max-320 {
                    width: rem(110);
                }
            }

            input::placeholder {
                color: #474A57;
                font-family: 'Manrope';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 24px;

                @include max-500 {
                    font-size: 12px;
                }

                @include max-400 {
                    font-size: 10px;
                }

                @include max-320 {
                    font-size: 8px;
                }
            }

            button {
                background: $yellow;
                border: none;
                border-radius: 0px 5px 5px 0px;
                color: #141714;
                font-family: 'Manrope';
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 20px;
                margin-top: 0.3rem;
                height: 100%;
                width: rem(157);

                @include max-500 {
                    font-size: 12px;
                    width: rem(130);
                }

                @include max-400 {
                    font-size: 10px;
                    margin-top: 0.2rem;
                    width: rem(110);
                }

                @include max-320 {
                    font-size: 8px;
                    width: rem(90);
                }
            }
        }
    }

    &__third-section {
        margin: 15% 0 25%;
    }
}

.faq-body {
    background-color: $backgrond-color;
    padding: 0 10rem;
}

.faq-row {
    padding: 0 !important;
    border-bottom: none !important;
    margin-bottom: 1rem;
}


.row-title {
    border-radius: 5px;
    background-color: $light-blue;
    font-size: 14px !important;
    font-weight: 700;
    line-height: 24px;
}

.row-title-text {
    margin-left: 1rem;
}

.row-content {
    background-color: $backgrond-color;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
}

.row-content-text {
    font-size: 16px !important;
    margin: rem(28) 0 rem(38) rem(16);
}