// BREAKPOINTS

@mixin min-500 {
    @media screen and (min-width: rem(500)) {
        @content;
    }
}

@mixin min-600 {
    @media screen and (min-width: rem(600)) {
        @content;
    }
}

@mixin min-700 {
    @media screen and (min-width: rem(700)) {
        @content;
    }
}

@mixin min-320-max-380 {
    @media screen and (min-width: rem(320)) and (max-width: rem(380)) {
        @content;
    }
}

@mixin max-320 {
    @media screen and (max-width: rem(320)) {
        @content;
    }
}

@mixin max-400 {
    @media screen and (max-width: rem(400)) {
        @content;
    }
}

@mixin max-500 {
    @media screen and (max-width: rem(500)) {
        @content;
    }
}

@mixin max-600 {
    @media screen and (max-width: rem(600)) {
        @content;
    }
}

@mixin max-700 {
    @media screen and (max-width: rem(700)) {
        @content;
    }
}

@mixin min-800 {
    @media screen and (min-width: rem(800)) {
        @content;
    }
}

@mixin max-800 {
    @media screen and (max-width: rem(800)) {
        @content;
    }
}

@mixin min-1000 {
    @media screen and (min-width: rem(1000)) {
        @content;
    }
}

@mixin max-930 {
    @media screen and (max-width: rem(930)) {
        @content;
    }
}

@mixin max-1000 {
    @media screen and (max-width: rem(1000)) {
        @content;
    }
}

@mixin max-1050 {
    @media screen and (max-width: rem(1050)) {
        @content;
    }
}

@mixin max-1080 {
    @media screen and (max-width: rem(1080)) {
        @content;
    }
}

@mixin max-1150 {
    @media screen and (max-width: rem(1150)) {
        @content;
    }
}

@mixin max-1180 {
    @media screen and (max-width: rem(1180)) {
        @content;
    }
}

@mixin min-1250 {
    @media screen and (min-width: rem(1250)) {
        @content;
    }
}

@mixin max-1280 {
    @media screen and (max-width: rem(1280)) {
        @content;
    }
}

@mixin max-1215 {
    @media screen and (min-width: rem(800)) and (max-width: rem(1215)) {
        @content;
    }
}

@mixin max-1400 {
    @media screen and (max-width: rem(1400)) {
        @content;
    }
}

@mixin max-1420 {
    @media screen and (max-width: rem(1420)) {
        @content;
    }
}

@mixin max-1292 {
    @media screen and (max-width: rem(1292)) {
        @content;
    }
}


@mixin min450-max800 {
    @media screen and (min-width: rem(450)) and (max-width: rem(800)) {
        @content;
    }
}

@mixin min700-max800 {
    @media screen and (min-width: rem(700)) and (max-width: rem(800)) {
        @content;
    }
}