.c-upcoming {
    background-color: $white;
    border: 1.16px solid #00BBF1;
    border-radius: 11.64px;
    padding: rem(56.35) rem(18.37) rem(143.77) rem(29.73);
    position: relative;

    @include max-930 {
        padding: rem(30) rem(18.37) rem(30) rem(29.73);
    }

    @include max-800 {
        width: 100%;
    }

    @include max-600 {
        width: 85%;
    }

    @include max-320 {
        padding: 1rem 0;
    }

    h3 {
        font-family: 'Goudy Bookletter 1911', serif;
        font-size: 17px;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: 0em;
        text-align: left;

        @include max-930 {
            font-size: 14px;
        }

    }

    &__first,
    &__second,
    &__third,
    &__fourth {
        border-radius: 71.66px;
        margin-bottom: rem(5.02);

        &--list {
            display: flex;
            font-size: 9px;
            line-height: 14px;
            letter-spacing: 0em;
            text-align: left;

            @include max-1150 {
                font-size: 7px;
            }

            @include max-800 {
                align-items: center;
                height: 100%;
            }

            &-hour,
            &-patient,
            &-type {
                margin: rem(19.35) rem(40) rem(16.48) 0;
                width: rem(85);

                @include max-1150 {
                    width: rem(50);
                }

                @include max-930 {
                    margin: 0.209375rem 1.5rem 0.03rem 0;
                }

                @include max-500 {
                    margin-right: 0;
                }

                span {
                    margin-left: rem(8.85);
                }
            }

            &-hour {
                margin-left: rem(15.8);
            }

            &-status {
                margin-right: 0 !important;
                width: rem(132);
                text-align: center;

                span {
                    margin-left: rem(8.85);
                }

                @include max-320 {
                    width: rem(68);
                }
            }

        }
    }

    &__first,
    &__third,
    &__fourth {
        border: 0.72px solid #CACACA;

        @include max-320 {
            height: rem(31.81);
        }
    }

    &__second,
    &__third {

        &--list {

            &-status {
                color: #00BBF1;
                font-weight: 700;
                text-align: center;
                margin: rem(19.35) rem(22) rem(16.48) 0;

                @include max-930 {
                    margin: 0.209375rem 1.5rem 0.03rem 0;
                }

                @include max-800 {
                    position: absolute;
                    right: 0;
                }
            }
        }
    }

    &__first {
        position: relative;

        &--list {

            &-status {

                &__ellipse {
                    background-color: #00BCF2;
                    border-radius: 25.5px;
                    position: absolute;
                    right: 0;
                    top: 0;
                    height: rem(49.81);
                    width: rem(131.86);

                    @include max-1150 {
                        height: rem(63.81);
                    }

                    @include max-930 {
                        height: rem(31.81);
                        width: rem(95);
                    }

                    @include max-320 {
                        width: rem(64);
                    }

                }

                &__checklist {
                    background-color: $white;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    height: rem(30.1);
                    position: absolute;
                    top: 0.5rem;
                    left: 3.5rem;
                    width: rem(30.1);

                    @include max-1150 {
                        top: 1rem;
                    }

                    @include max-930 {
                        left: 2.5rem;
                        top: 0.5rem;
                        height: rem(15.1);
                        width: rem(15.1);
                    }

                    @include max-320 {
                        left: 1.5rem;
                    }

                    svg {
                        align-self: center;
                        color: #00BBF1;
                    }
                }
            }
        }
    }

    &__second {
        border: 1.43px solid #00BBF1;

        @include max-930 {
            height: rem(31.81);
        }
    }

    &__third {
        @include max-930 {
            &--list {
                &-type {
                    width: 60px;
                }
            }
        }
    }

    &__fourth {
        position: relative;

        &--list {

            &-status {

                &__ellipse {
                    background-color: $red;
                    border-radius: 25.5px;
                    color: $white;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    position: absolute;
                    right: 0;
                    top: 0;
                    height: rem(49.9);
                    width: rem(131.86);

                    @include max-1150 {
                        height: rem(63.81);
                    }

                    @include max-930 {
                        height: rem(31.81);
                        width: rem(95);
                    }

                    @include max-320 {
                        width: rem(64);
                    }

                    div {
                        align-self: center;
                        background-color: $white;
                        border-radius: 11.47px;
                        color: $bold-black;
                        margin-top: rem(5);
                        padding: 5px;
                        width: rem(63.78);

                        @include max-930 {
                            margin-top: 0;
                            padding: 1px;
                        }

                        @include max-320 {
                            width: rem(50);
                        }
                    }

                    span {
                        margin-left: 0;

                        @include max-930 {
                            font-size: 6px;
                        }
                    }
                }

            }
        }
    }
}