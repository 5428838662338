// COLOURS
$backgrond-color: #EEF7FE;
$light-blue: #D4ECFF;
$brand-blue: #50BAEE;
$brand-darker: #1B5BFF;
$black: #000000;
$bold-black: #151714;
$paragraph-black: #18191F;
$white: #ffffff;

$red: #FF3939;

$green: #0FDD92;

$yellow: #FFDF34;

// FONTS
$base-font: "Montserrat",
Open Sans,
sans-serif;
$secondary-font: "Manrope";

// LAYOUT VALUES
$site-width: 1250;