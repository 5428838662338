.c-btn {
    font-weight: 700;
    align-items: center;
    display: flex;
    padding: rem(16) rem(20);
    outline: 0;
    cursor: pointer;

    &--small {
        color: $bold-black;
        padding: rem(8) rem(20);

        @include min-1000 {
            padding: rem(6) rem(16);
        }
    }

    &--primary {
        background-color: $yellow;
        border: 2px solid $yellow;
        border-radius: 16px;
        transition: background-color 0.2s ease-in;

        &:hover {
            background-color: $brand-darker;
            border: 2px solid $brand-darker;
        }

        & .c-btn__icon {
            color: #fff;
        }
    }

    &__text {
        display: block;
        white-space: nowrap;

        &--center {
            text-align: center;
        }
    }
}