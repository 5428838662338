.c-contactus {
    align-items: center;
    background-color: $backgrond-color;
    display: flex;
    flex-direction: column;

    h1 {
        font-family: $base-font;
        font-size: 50px;
        font-weight: 500;
        letter-spacing: 0em;
        margin-bottom: rem(10);
        text-align: center;

        @include max-320 {
            font-size: 30px;
        }
    }

    h4 {
        font-family: $secondary-font;
        font-size: 18px;
        letter-spacing: 0em;
        margin-top: 0;
        text-align: center;

        @include max-320 {
            font-size: 12px;
        }
    }

    a {
        color: $brand-blue;
        text-decoration: none;
    }

    form {
        display: flex;
        flex-direction: column;
        width: rem(499);

        @include max-500 {
            width: rem(319);
        }

        @include max-320 {
            width: rem(260);
        }
    }

    &__name,
    &__subject,
    &__email {
        margin-bottom: rem(20);

        input {
            border: none;
            border-radius: 5px;
            outline: none;
            padding: 1rem 0 1rem 1rem;
            width: 95%;
        }

        input::placeholder {
            color: #474A57;
            font-family: $secondary-font;
        }
    }

    &__message {

        textarea {
            border: none;
            border-radius: 5px;
            color: #474A57;
            font-family: $secondary-font;
            outline: none;
            padding: 1rem;
            width: 92%;

            @include max-320 {
                width: 89%;
            }
        }
    }

    &__send {
        align-self: center;
        margin: rem(20) 0 rem(296) 0;

        @include max-800 {
            margin-bottom: rem(234);
        }

        input {
            background-color: $brand-darker;
            border: none;
            border-radius: 16px;
            color: $white;
            height: rem(59);
            width: rem(221);
        }
    }
}