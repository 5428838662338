.c-footer {
    background-color: $brand-darker;
    color: $white;


    &__blocks {
        display: flex;
        justify-content: center;
        padding-top: rem(60);

        @include max-800 {
            justify-content: center;
        }

        a {
            color: $white;
            text-decoration: none;
        }

        ul {
            list-style: none;
            padding: 0;
        }

        li {
            font-family: 'DM Sans';
            font-size: 15px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: -0.20454545319080353px;
            text-align: left;
        }

        h4 {
            font-family: 'DM Sans';
            font-size: 22px;
            font-weight: 400;
            line-height: 29px;
            letter-spacing: -0.30000001192092896px;
            text-align: left;

        }

        h5 {
            color: $brand-blue;
            font-family: 'DM Sans';
            font-size: 17px;
            font-weight: 400;
            line-height: 22px;
            letter-spacing: -0.23181818425655365px;
            text-align: left;

        }

        &--first {

            @include max-930 {
                margin-left: 1rem;
            }

            @include max-800 {
                display: none;
            }
        }

        &--second {
            margin: 0 rem(189);

            @include max-800 {
                margin: 0;
                margin-right: 3rem;
            }
        }
    }

    &__button {
        height: 60px;
        margin: 0 auto;
        padding: 0 !important;
        position: relative;
        display: flex;
        justify-content: center;
        width: 215px;
        z-index: 1;
        color: $bold-black !important;
        margin: 0 auto;
        text-decoration: none;
    }

    &__links {
        margin-top: rem(167);
        padding-bottom: rem(115);

        @include max-800 {
            margin: 0;
        }

        ul {
            display: flex;
            justify-content: center;
            list-style: none;
            padding: 0;

            @include max-800 {
                align-items: center;
                flex-direction: column;
                text-align: left;
            }
        }

        &-docs {
            display: flex;
            margin-right: 22.5rem;

            @include max-930 {
                margin-right: 16.5rem;
            }

            @include max-800 {
                flex-direction: column;
                margin: 0;
            }

            &--terms {
                margin: 0 rem(39) 0 0;
                
                a {
                    text-decoration: none;
                    color: #ffffff;
                }

                @include max-800 {
                    margin: 0;
                }
            }

            &--privacy {

                @include max-930 {
                    margin-right: 5rem;
                }


                @include max-800 {
                    margin: 0;
                    margin-top: rem(25);
                }
            }
        }

        &--social-media {
            display: flex;

            @include max-800 {
                margin-top: rem(30);
                margin-left: -34px;
            }
        }

        &--facebook {
            margin-right: rem(40.04);

            @include max-800 {
                margin-right: rem(20.04);
            }
        }

        &--instagram {
            height: rem(22);
            margin-left: rem(40);
            width: rem(22);

            @include max-800 {
                margin-left: rem(20.04);
            }
        }
    }
}