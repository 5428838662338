.c-features {
    background-color: $backgrond-color;
    font-family: "Montserrat";

    a {
        text-decoration: none;
    }

    &__heading {
        align-items: center;
        display: flex;
        flex-direction: column;

        h1 {
            font-family: Gotham;
            font-size: 50px;
            font-weight: 500;
            line-height: 50px;
            letter-spacing: 0em;

            @include max-500 {
                font-size: 24px
            }
        }

        h4 {
            color: $brand-blue;
            font-size: 24px;
            font-weight: 400;
            line-height: 50px;
            letter-spacing: 0em;
            text-align: center;

            @include max-500 {
                font-size: 14px
            }
        }

        &--button {
            color: $bold-black;
            font-size: 16px;
            font-weight: 700;
            line-height: 20px;
            letter-spacing: 0em;
            text-align: center;
        }
    }

    &__first-section,
    &__second-section,
    &__third-section,
    &__fourth-section {
        position: relative;
    }

    &__first-section,
    &__third-section {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: rem(80) 0;

        @include max-800 {
            flex-direction: column;
            min-height: rem(750);
            padding-top: 0;
        }

        @include max-600 {
            display: block;
        }

        @include max-320 {
            min-height: rem(590);
        }

        &--left,
        &__right {
            width: 50%;
        }

        &--left {
            position: relative;

            @include max-800 {
                margin: rem(100) 0 rem(62.44) 0;
            }

            @include max-600 {
                width: 100%;
                padding: 0;
                font-size: 12px;
            }

            @include max-320 {
                margin-top: 2rem;
            }

            h3 {
                padding: 0 20rem 0 0;

                @include max-1280 {
                    padding-right: 10rem;
                }

                @include max-1000 {
                    padding: 0 2.5rem;
                }

                @include max-800 {
                    font-size: 20px;
                    padding: 0;
                    margin: 1rem;
                }
            }

            p {
                padding: 0 20rem 0 0;

                @include max-1280 {
                    padding-right: 10rem;
                }

                @include max-1000 {
                    padding: 0 2.5rem;
                }

                @include max-800 {
                    padding: 0;
                    margin: 1rem;
                }
            }

            span {
                color: $brand-blue;
            }

            &__button {
                border: 2px solid $brand-blue;
                border-radius: 16px;
                color: $brand-blue;
                display: flex;
                justify-content: center;
                padding: rem(25);
                width: rem(221);


                @include max-1000 {
                    margin: 0 2.5rem;
                }

                @include max-800 {
                    bottom: -29rem;
                    position: absolute;
                }

                @include max-600 {
                    bottom: -27rem;
                    left: 7rem;
                }

                @include max-500 {
                    left: 1.5rem;
                }

                @include max-320 {
                    left: 0;
                    width: rem(160);
                }
            }
        }

        &--right {
            @include max-600 {
                display: flex;
                justify-content: center;
            }
        }
    }



    &__vector1 {
        position: absolute;
        right: 0;
        width: rem(450);

        @include max-800 {
            display: none;
        }
    }

    &__vector2 {

        &--mobile {
            display: none;

            @include max-800 {
                display: block;
                position: absolute;
                top: 24rem;
                right: 0;
                width: rem(274);
            }

            @include max-600 {
                top: 9rem;
            }

            @include max-400 {
                top: 7rem;
                width: 67%;
            }
        }
    }

    &__vector3 {
        position: absolute;
        width: 90%;
        top: -5rem;
    }

    &__ellipse1 {
        left: 0;
        position: absolute;

        @include max-800 {
            display: none;
        }
    }

    &__ellipse3 {
        left: 42rem;
        position: absolute;
    }

    &__second-section,
    &__fourth-section {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: rem(635);
        padding-bottom: rem(210);

        &--left,
        &--right {
            position: relative;
            width: 50%;
        }

        &--left {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &--right {
            margin-right: rem(141);

            h3 {
                color: $bold-black;
                font-size: 28px;
                font-weight: 500;
                line-height: 40px;
                letter-spacing: 0em;
                position: relative;
                text-align: left;
            }

            span {
                color: $brand-blue;
            }

            p {
                font-size: 18px;
                line-height: 29px;
                letter-spacing: 0em;
                position: relative;
                text-align: left;
            }

            &__button {
                border: 2px solid $brand-blue;
                border-radius: 16px;
                color: $brand-blue;
                display: flex;
                justify-content: center;
                padding: rem(25);
                width: rem(221);
            }
        }
    }

    &__profile {
        background-color: $white;
        border-radius: 14.33px;
        display: flex;
        padding: rem(16) rem(32.73) rem(4) rem(42.5);
        position: relative;
        width: rem(530);

        &--left {
            width: 30%;

            &__first-button {
                background-color: $brand-blue;
                border-radius: 8.05px;
                color: $white;
                display: flex;
                justify-content: center;
                align-items: center;
                height: rem(29.67);
                margin-bottom: rem(7.04);
                font-size: 8px;
                line-height: 10px;
                letter-spacing: 0em;
                text-align: center;
            }

            &__second-button {
                background-color: $white;
                border: 1.01px solid #00BBF1;
                border-radius: 8.05px;
                color: $brand-blue;
                display: flex;
                justify-content: center;
                align-items: center;
                height: rem(29.67);
                margin-bottom: rem(24.64);
                font-size: 8px;
                line-height: 10px;
                letter-spacing: 0em;
                text-align: center;
            }

            &__availability {
                margin-left: rem(10);

                &--heading {
                    font-size: 7px;
                    font-weight: 700;
                    line-height: 12px;
                    letter-spacing: 0em;
                    margin: 0 0 rem(10.56) 0;
                    text-align: left;

                    svg {
                        font-size: 7px;
                        margin-right: rem(9.05);
                    }
                }

                &--first,
                &--second,
                &--third {
                    border: 0.5px solid #00BBF1;
                    border-radius: 8.05px;
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: rem(3.72);
                    width: 90%;

                    span {
                        font-size: 6px;
                        line-height: 10px;
                        letter-spacing: 0em;
                        margin: rem(5.41) 0 rem(4.4) rem(12.57);
                        text-align: left;
                    }

                    svg {
                        font-size: 6px;
                        margin: rem(6.71) rem(5.83) rem(11.12) 0;
                    }
                }

                &--first {
                    margin-top: rem(10.56);
                }
            }
        }

        &--right {
            display: flex;
            flex-direction: column;
            margin-left: 3rem;
            position: relative;
            width: 70%;

            &__heading {
                display: flex;
                justify-content: space-between;

                h1 {
                    font-family: 'Goudy Bookletter 1911', serif;
                    font-size: 25px;
                    font-weight: 400;
                    line-height: 10px;
                    letter-spacing: 0em;
                    text-align: left;

                }

                &--container {
                    align-items: center;
                    display: flex;
                }

                &--reviews {

                    &-star-div {
                        margin-right: rem(25.15);

                        &__text {
                            font-size: 6px;
                            line-height: 10px;
                            letter-spacing: 0em;
                            text-align: left;
                        }
                    }
                }

                &--price {
                    background-color: #1C7893;
                    /* top-left | top-right | bottom-right | bottom-left */
                    border-radius: 0 16px 16px 0px;
                    color: $white;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    font-family: 'Open Sans';
                    font-size: 5px;
                    font-weight: 400;
                    height: 100%;
                    line-height: 7px;
                    letter-spacing: 0em;
                    padding: 0rem 1.8rem 0;
                    text-align: left;

                    &__number {
                        font-family: $base-font;
                        font-size: 13px;
                        font-weight: 700;
                        line-height: 16px;
                        letter-spacing: 0em;
                        text-align: left;
                    }

                }
            }

            &__line {
                position: absolute;
                top: 3.3rem;
                left: 0rem;
            }

            &__availability {
                h5 {
                    font-size: 7px;
                    font-weight: 700;
                    line-height: 12px;
                    letter-spacing: 0em;
                    margin-top: rem(32.69);
                    text-align: left;

                }

                &--options {
                    font-size: 6px;
                    line-height: 10px;
                    letter-spacing: 0em;
                    text-align: left;

                    span {
                        margin-right: rem(20.08);
                    }

                    svg {
                        margin-right: rem(13.08);

                    }
                }
            }

            &__about {

                h5 {
                    font-family: Montserrat;
                    font-size: 7px;
                    font-weight: 700;
                    line-height: 12px;
                    letter-spacing: 0em;
                    margin-bottom: 0;
                    text-align: left;
                }

                p {
                    font-size: 6px;
                    line-height: 10px;
                    letter-spacing: 0em;
                    margin-right: rem(10);
                    text-align: left;
                }
            }

            &__reviews {
                margin-top: auto;

                &--heading {
                    align-items: center;
                    display: flex;
                    justify-content: space-between;

                    h5 {
                        font-size: 7px;
                        font-weight: 700;
                        line-height: 12px;
                        letter-spacing: 0em;
                        text-align: left;
                    }

                    span {
                        font-size: 7px;
                        line-height: 12px;
                        letter-spacing: 0em;
                        text-align: right;
                    }
                }

                p {
                    font-size: 6px;
                    line-height: 10px;
                    letter-spacing: 0em;
                    text-align: left;
                }
            }
        }
    }

}