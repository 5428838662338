.c-header {
    font-family: 'Montserrat', sans-serif;
    margin-bottom: 0.625rem;

    &__inner {
        display: flex;
        position: relative;
        width: 100%;

        @include min-700 {
            margin: auto;
            max-width: rem($site-width);
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: space-between;
        }
    }

    &__burger {


        @include min-700 {
            display: none;
        }

        &-links {
            color: #141714;
            padding: 1rem;
            text-align: center;
            text-decoration: none;
        }
    }

    &__navigation-bar {
        @include max-700 {
            display: none;
        }

        ul {
            display: flex;
            list-style-type: none;
        }

        &-links {
            padding: 1.625rem 1.9375rem;

            a {
                color: #141714;
                text-decoration: none;
            }
        }

        &-button {
            margin-top: 0.3125rem;

            a {
                color: #141714;
                text-decoration: none;
            }
        }
    }



    &__logo-wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 rem(16);
        padding: 1rem;
        position: relative;
        order: 1;
        width: rem(180);

        @include min-700 {
            order: 0;
            flex-basis: rem(160);
            margin: 0 rem(16);
        }
    }

    &__logo {
        -ms-interpolation-mode: nearest-neighbor;
        width: 100%;

        @include max-320 {
            width: 70%;
        }
    }
}

/* Position and sizing of burger button */
.bm-burger-button {
    position: absolute;
    width: 58px;
    height: 30px;
    right: 36px;
    top: 36px;

    @include max-320 {
        right: 25px;
        top: 15px;
        width: 40px;
    }
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
    background: #D4ECFF;
    border-radius: 40px;
}


/* Position and sizing of clickable cross button */
.bm-cross-button {
    height: 24px;
    width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
    background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
    position: fixed;
    height: 100%;
}

/* General sidebar styles */
.bm-menu {
    background: #fff;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
    fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
}

/* Individual item */
.bm-item {
    display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
    background: rgba(0, 0, 0, 0.3);
}