.c-about {
    font-family: $base-font;

    &__first-section {
        background-color: $brand-blue;
        color: #EBEBEB;
        display: flex;
        justify-content: center;
        padding: rem(139) rem(334) rem(132.69) rem(314);
        position: relative;

        @include max-1000 {
            padding: rem(139) rem(190) rem(132.69) rem(202);
        }

        @include max-600 {
            padding: 8.6875rem 6.875rem 8.293125rem 7.625rem;
        }

        @include max-500 {
            padding: 8.6875rem 3.875rem 8.293125rem 4.625rem;
        }

        @include max-320 {
            padding: 6rem 1rem;
        }

        h1 {
            margin: 0;
            position: relative;
            z-index: 1;
        }

        span {
            color: $brand-darker;
        }

        &--ellipses {

            @include max-800 {
                display: none;
            }

            &-mobile {
                display: none;

                @include max-800 {
                    display: block;
                }
            }
        }

        &--heading {
            position: relative;
        }

        &--ellipse1,
        &--ellipse2,
        &--ellipse3,
        &--ellipse4 {
            position: absolute;

            &-mobile {
                position: absolute;
            }
        }

        &--ellipse2,
        &--ellipse3,
        &--ellipse4 {

            &-mobile {
                width: 55px;
            }
        }

        &--ellipse1 {
            right: 1rem;
            top: 1rem;


            @include max-800 {
                display: none;
            }

            &-mobile {
                bottom: 0;
                right: 0;

                @include max-320 {
                    width: rem(140);
                }
            }
        }

        &--ellipse2 {
            right: 0;
            top: 1rem;

            &-mobile {
                top: 1rem;
                right: 0;
            }
        }

        &--ellipse3 {
            left: 15rem;
            top: 0;

            &-mobile {
                top: 1rem;
                left: 0;
            }
        }

        &--ellipse4 {
            left: 0;
            top: 1rem;

            &-mobile {
                left: 0;
                top: 10rem;
            }
        }
    }

    &__second-section {

        ul {
            display: flex;
            justify-content: center;
            list-style: none;
        }

        li {

            @include max-320 {
                font-size: 12px;
            }
        }

        a {
            color: $brand-blue;
            text-decoration: none;
        }

        &--heading {

            &-whyus {
                margin: 0 rem(70);

                @include max-400 {
                    margin: 0 rem(20);
                }

                @include max-320 {
                    margin: 0 rem(5);
                }
            }
        }

        &--diagram {
            display: flex;
            margin-bottom: rem(47);

            @include max-1000 {
                flex-direction: column;
            }

            &__left {
                display: flex;
                flex-direction: column;
                padding-left: rem(77);
                width: 30%;

                @include max-1400 {
                    padding-left: rem(47);
                    width: 15%;
                }

                @include max-1000 {
                    display: none;
                }

                img {
                    @include max-1400 {
                        margin-top: 10rem;
                    }
                }

                h4 {
                    align-self: center;
                }
            }

            &__right {
                margin-left: rem(50);
                position: relative;

                @include max-1000 {
                    align-self: center;
                    margin-top: rem(50);
                }

                @include max-400 {
                    margin-left: 0;
                }

                @include max-320 {
                    margin-top: 1rem;
                }

                h1 {
                    color: #00BBF1;
                    font-size: 40px;
                    font-weight: 500;
                    line-height: 50px;
                    letter-spacing: 0em;
                    margin-top: rem(125);
                    text-align: left;

                    @include max-1000 {
                        display: none;
                    }
                }

                h4 {

                    @include max-320 {
                        font-size: 13px;
                    }
                }

                p {

                    @include max-320 {
                        font-size: 12px;
                    }
                }

                &-block {
                    display: flex;

                    @include max-1000 {
                        flex-direction: column;
                    }
                }

                &__first,
                &__second,
                &__third,
                &__fourth {
                    background-color: $white;
                    border: 1.13px solid #1B5BFF;
                    border-radius: 11.34px;
                    height: rem(176);
                    padding: rem(19);
                    width: rem(363);

                    @include max-1150 {
                        width: rem(250);
                    }

                    @include max-1000 {
                        width: rem(363);
                    }

                    @include max-500 {
                        width: rem(250);
                    }

                    @include max-400 {
                        height: rem(270);
                        width: rem(200);
                    }

                    @include max-320 {
                        height: rem(176);
                    }
                }

                &__first,
                &__third {
                    margin-bottom: rem(60);
                }

                &__second {

                    @include max-1000 {
                        margin-bottom: rem(60);
                    }
                }

                &-line1,
                &-line2,
                &-line3,
                &-line4 {
                    position: absolute;
                }

                &-line1 {
                    top: 2rem;
                    left: 25.2rem;

                    @include max-1150 {
                        left: 18.2rem;
                    }

                    @include max-1000 {
                        left: -1.8rem;
                        top: 6rem;
                    }
                }

                &-line2 {
                    top: 19rem;
                    left: 25.2rem;

                    @include max-1000 {
                        left: -1.8rem;
                        top: 24rem;
                    }
                }

                &-line3 {
                    top: 2rem;
                    left: -1.8rem;

                    @include max-1000 {
                        top: 6rem;
                    }
                }

                &-line4 {
                    top: 19rem;
                    left: -1.8rem;

                    @include max-1000 {
                        top: 24rem;
                    }
                }

                &--left {
                    margin-right: rem(27);
                    position: relative;

                    @include max-1000 {
                        margin: 0 0 0 rem(27);
                    }
                }

                &--right {
                    margin-top: rem(107);
                    margin: rem(107) 0 0 rem(27);
                    position: relative;

                    @include max-1000 {
                        margin-top: 0;
                    }
                }

                &-button {
                    justify-content: center;
                    display: flex;
                    height: rem(59);
                    margin: 0 auto;
                    padding: 0;
                    width: rem(221);

                    a {
                        color: $bold-black;
                    }

                    @include max-1000 {
                        margin-top: rem(50);
                        width: 100%;
                    }
                }

                &--ellipse6 {

                    &-1,
                    &-2,
                    &-3,
                    &-4,
                    &-5 {
                        position: absolute;
                    }

                    &-1 {
                        top: 7rem;
                        left: 26.2rem;

                        @include max-1150 {
                            left: 19.2rem;
                        }

                        @include max-1000 {
                            display: none;
                        }
                    }



                    &-2 {
                        top: 1.3rem;
                        left: 26.2rem;

                        @include max-1150 {
                            left: 19.2rem;
                        }

                        @include max-1000 {
                            top: 5.3rem;
                            left: -2.4rem;
                        }
                    }

                    &-3 {
                        left: 26.2rem;
                        top: 18.2rem;

                        @include max-1000 {
                            left: -2.4rem;
                            top: 23.2rem;
                        }
                    }

                    &-4 {
                        top: 1.2rem;
                        left: -2.5rem;

                        @include max-1000 {
                            left: -2.4rem;
                            top: 5.2rem;
                        }
                    }

                    &-5 {
                        left: -2.5rem;
                        top: 18.3rem;

                        @include max-1000 {
                            left: -2.4rem;
                            top: 23.3rem;
                        }
                    }
                }

                &--line {
                    margin-top: -6rem;

                    @include max-1000 {
                        margin: 0;
                        position: absolute;
                        top: 6.8rem;
                    }

                    @include max-400 {
                        height: rem(1010);
                    }

                    @include max-320 {
                        height: rem(840);
                    }
                }
            }

        }
    }

    &__third-section {
        background-color: $light-blue;
        padding: rem(95) rem(154) rem(76) rem(164);
        position: relative;

        @include max-1080 {
            padding: rem(95) rem(100) rem(76);
        }

        @include max-1000 {
            padding: rem(75) rem(50) rem(56);
        }

        @include max-800 {
            padding-right: 1.5rem;
        }

        h1 {
            color: $brand-darker;
            font-size: 40px;
            font-weight: 500;
            line-height: 50px;
            letter-spacing: 0em;
            text-align: left;

            @include max-1000 {
                font-size: 25px;
            }

            @include max-800 {
                display: none;
            }
        }

        &--ellipse {
            position: absolute;
            right: 0;
            top: 1rem;

            @include max-800 {
                display: none;
            }
        }

        &__blocks {
            display: flex;

            @include max-800 {
                flex-direction: column;
            }

            p {
                @include max-1292 {
                    font-size: 12px;
                }

                @include max-800 {
                    font-size: 16px;
                    font-weight: 300;
                    line-height: 26px;
                    letter-spacing: 0em;
                    text-align: left;
                }
            }

            h4 {

                @include max-800 {
                    font-size: 18px;
                }
            }

            &--first,
            &--second,
            &--third {
                margin-right: rem(30);

                @include max-800 {
                    margin-right: 0;
                }
            }

            &--fourth {
                position: relative;
            }

            &__numbers {
                margin-right: rem(16);

                @include max-1420 {
                    width: 40px;
                }

                @include max-1000 {
                    width: 20px;
                }

                @include max-800 {
                    width: 72px;
                }
            }

            &__lines {

                @include max-1292 {
                    width: 110px;
                }

                @include max-800 {
                    display: none;
                }
            }
        }
    }
}