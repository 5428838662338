.c-terms {

    &__ul-nodecoration {
        li {
            list-style-type: none;
        }
    }

    &__container {
       padding: 0 10rem;

       h1 {
        font-size: 20px;
       }

       h3, p, td {
        font-size: 14px;
       }
    }

    &__generaldefinitions, &__definitions {
        td:first-child {
            width: 140px;
        }
    }

    &__generaldefinitions {
        td:first-child {
            font-weight: 600;
        }
    }

    &__privacy {
        text-align: right;
    }
}