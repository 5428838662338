.c-home {
    font-family: 'Montserrat', sans-serif;

    a {
        color: $bold-black;
        margin: 0 auto;
        text-decoration: none;
    }

    &__first-section {
        background-color: $brand-blue;
        display: flex;

        h1 {
            font-size: 50px;
            font-weight: 500;

            @include max-800 {
                font-size: 25px;
                line-height: 40px;
                text-align: center;
            }

            @include max-1215 {
                font-size: 30px;
            }
        }

        p {
            font-size: 18px;

            @include max-800 {
                display: none;
            }
        }

        &--left {
            display: flex;
            align-items: center;
            color: $white;
            width: 50%;

            @include max-800 {
                justify-content: center;
                width: 100%;
            }

            &-container {
                display: flex;
                justify-content: center;
                align-items: center;

                @include max-930 {
                    display: block;
                    margin-left: 4rem;
                }

                @include max-800 {
                    padding: 3rem;
                    margin-left: 0rem;
                }

                &--ellipse {
                    height: 0;
                }

                &--mobile-ellipses {
                    @include min-800 {
                        display: none;
                    }
                }

                &-wording {
                    position: relative;
                    z-index: 1;
                    margin: rem(139) 0 rem(127);
                }
            }

            &-button {
                display: flex;
                justify-content: center;
                height: 60px;
                padding: 0;
                width: 215px;

                @include max-800 {
                    margin: 0 auto;
                }
            }
        }

        &--right {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 50%;
            align-items: center;

            &--ellipses {
                height: 0;
            }

            &-laptop {
                align-self: center;
                position: relative;
                z-index: 1;
                width: 60%;
            }

            @include max-800 {
                display: none;
            }
        }

        &--ellipse1,
        &--ellipse2,
        &--ellipse3,
        &--ellipse4,
        &--ellipse5 {
            position: relative;
        }

        &--ellipse2, &--ellipse3, &--ellipse5 {
            width: 32%;
        }

        &--ellipse1 {
            top: 9.1875rem;
            left: -17rem;

            @include max-800 {
                display: none;
            }
        }

        &--ellipse2 {
            top: -6rem;
            left: 23rem;
        
            @include max-1180 {
                left: 21rem;
            }

            @include max-1050 {
                left: 19rem;
                top: -5rem;
            }

            @include max-930 {
                left: 16rem;
            }
        }

        &--ellipse3 {
            top: 5rem;
            right: 10rem;

            @include max-930 {
                right: 8rem;
            }
        }

        &--ellipse4 {
            left: 0rem;
            top: 14.5rem;
            width: 6%;

            @include max-1400 {
                top: 15.5rem;
            }

            @include max-1080 {
                top: 17rem;
            }

            @include max-930 {
                top: 14rem;
            }
        }

        &--ellipse5 {
            top: -4rem;
            left: 25rem;

            @include max-1180 {
                left: 22rem;
            }

            @include max-1050 {
                top: -2rem;
                left: 19rem;
            }

            @include max-930 {
                left: 16rem;
            }
        }

        &--ellipse6 {
            display: none;
        }

        &--mobile-ellipse1,
        &--mobile-ellipse2,
        &--mobile-ellipse5,
        &--mobile-ellipse6 {
            position: absolute;
        }

        &--mobile-ellipse3,
        &--mobile-ellipse4 {
            display: none;
        }

        &--mobile-ellipse1 {
            left: 0;
            top: 24rem;
            width: 10%;
        }

        &--mobile-ellipse2 {
            right: 0;
            top: 9rem;
            width: 15%;
        }

        &--mobile-ellipse5 {
            right: 0;
            top: 28rem;
            width: 20%;
        }

        &--mobile-ellipse6 {
            left: 0;
            top: 10rem;
            width: 12%;
        }
    }

    &__second-section {
        background-color: $backgrond-color;
        display: flex;
        flex-direction: column;
        padding: rem(90);
        position: relative;

        @include max-1000 {
            padding: rem(30);
        }

        h1 {
            // text-align: center;
            margin: 0;
            position: relative;

            @include max-1000 {
                display: flex;
                flex-direction: column;
                text-align: center;
            }
        }

        span {
            color: $brand-darker;

            @include max-1000 {
                margin-bottom: 3rem;
            }
        }

        &--ellipse9,
        &--ellipse10,
        &--ellipse11,
        &--ellipse12,
        &--ellipse13,
        &--ellipse14 {
            position: absolute;
        }

        &--ellipse9,
        &--ellipse11,
        &--ellipse13 {
            left: 0;
        }

        &--ellipse10,
        &--ellipse14 {
            right: 0;
        }

        &--ellipse13,
        &--ellipse14 {
            bottom: 0;
        }

        &--ellipse9 {
            width: rem(123);

            @include max-800 {
                width: rem(90);
                top: 35rem;
            }
        }

        &--ellipse10 {
            width: rem(225);

            @include max-800 {
                width: rem(160);
            }
        }

        &--ellipse11 {
            top: 25rem;
            width: rem(388);

            @include max-800 {
                display: none;
            }
        }

        &--ellipse12 {
            bottom: 12rem;
            right: 10%;
            width: rem(128);

            @include max-800 {
                display: none;
            }

            &-mobile {
                display: none;

                @include max-800 {
                    display: block;
                    position: absolute;
                    right: 0;
                    bottom: 12rem;
                }
            }
        }

        &--ellipse13 {
            width: rem(250);

            @include max-800 {
                width: rem(120);
            }
        }

        &--ellipse14 {
            width: rem(170);

            @include max-800 {
                display: none;
            }
        }

        &--heading {
            display: flex;
            justify-content: center;

            &__img1 {
                height: 50.40304565904599px;
                margin: 0 0 1% -18%;
                width: 191.76750184912356px;

                @include max-1000 {
                    display: none;
                }
            }

            &__img2 {
                height: 50.40304565904599px;
                width: 191.76750184912356px;
                position: absolute;
                top: 3rem;
                left: 26rem;

                @include max-1000 {
                    left: 7rem;
                    top: 1rem;
                }

                @include min-320-max-380 {
                    width: rem(155);
                }

                @include max-320 {
                    width: 130px;
                }
            }

        }

        &--features {
            align-items: center;
            align-self: center;
            display: flex;
            flex-direction: column;
            position: relative;
            row-gap: rem(20);
            width: 700px;

            @include max-800 {
                width: rem(501);
            }

            @include max-500 {
                width: rem(360);
            }

            @include min-320-max-380 {
                width: rem(321);
            }

            @include max-320 {
                width: 14.25rem;
            }

            &__bigline {
                position: absolute;
                top: 2rem;

                @include max-800 {
                    height: 825px;
                }

                @include max-500 {
                    height: 770px;
                }

                @include max-320 {
                    height: 648px;
                }
            }

            &__ellipse {
                position: absolute;
                top: 1rem;

            }

            &__first {
                align-items: center;
                background-color: $white;
                border: 3px solid $brand-darker;
                border-radius: 11.34px;
                display: flex;
                flex-direction: column;
                height: rem(197);
                margin-top: 4rem;
                position: relative;
                width: 452px;
                z-index: 1;

                @include max-800 {
                    width: 333px;
                    height: rem(145);
                }

                @include max-500 {
                    width: 284.75px;
                    height: rem(130.87);
                }

                @include max-320 {
                    width: 221.47px;
                    height: rem(96.43);
                }

                h3 {
                    color: $bold-black;
                    font-size: 22px;
                    font-weight: 700;
                    margin-bottom: rem(39.74);

                    @include max-800 {
                        font-size: 16px;
                        line-height: 27px;
                        letter-spacing: 0em;
                    }

                    @include max-320 {
                        font-size: 12px;
                        line-height: 12px;
                        margin-bottom: 1.5rem;
                    }
                }

                &-fontawsome {
                    position: absolute;
                    left: -0.7rem;
                    top: -0.7rem;
                    z-index: 1;
                    font-size: 28px;
                    color: white;
                }

                &-calls {
                    display: flex;

                    &--video,
                    &--phone,
                    &--face {
                        align-items: center;
                        border-radius: 74.05358123779297px;
                        display: flex;
                        height: 48.134830474853516px;
                        justify-content: center;
                        width: 131.07484436035156px;
                        font-size: 9px;
                        line-height: 15px;
                        letter-spacing: 0em;

                        svg {
                            margin-right: rem(8.11);

                            @include max-320 {
                                margin-right: 0.2rem;
                            }
                        }

                        @include max-800 {
                            height: 35.494380950927734px;
                            width: 96.6539306640625px;
                            border-radius: 54.60673522949219px;
                            font-size: 5px;
                            line-height: 11px;
                            letter-spacing: 0em;
                            text-align: left;
                        }

                        @include max-500 {
                            width: 82.64px;
                            height: rem(30.34);
                        }

                        @include max-320 {
                            width: 64.28px;
                            height: rem(23.60);
                        }
                    }

                    &--video {
                        border: 1.48px solid #00BBF1;
                        margin-right: rem(8.15);

                        &-checklist {
                            align-items: center;
                            background-color: #00BBF1;
                            border-radius: 50%;
                            display: flex;
                            height: rem(18.51);
                            justify-content: center;
                            margin-left: rem(17.5);
                            width: rem(18.51);

                            @include max-800 {
                                margin-left: rem(10);
                                height: rem(13.65);
                                width: rem(13.65);
                            }

                            svg {
                                color: $white;
                                margin: 0;
                            }
                        }
                    }

                    &--phone {
                        border: 0.74px solid #E5E5E5;
                        margin-right: rem(8.15);
                    }

                    &--face {
                        border: 0.74px solid #E5E5E5;
                    }
                }
            }

            &__fourth {
                align-items: center;
                background-color: $white;
                border: 3px solid $brand-darker;
                border-radius: 11.34px;
                display: flex;
                flex-direction: column;
                height: rem(197);
                position: relative;
                width: 452px;
                z-index: 1;

                @include max-800 {
                    width: 333px;
                    height: rem(145);
                }

                @include max-500 {
                    width: 284.75px;
                    height: rem(130.87);
                }

                @include max-320 {
                    width: 221.47px;
                    height: rem(96.43);
                }

                h3 {
                    font-size: 18px;
                    font-weight: 700;
                    line-height: 29px;
                    letter-spacing: 0em;
                    text-align: center;

                    @include max-800 {
                        margin-bottom: 0;
                    }

                    @include max-320 {
                        font-size: 12px;
                        line-height: 12px;
                        margin-bottom: 1.5rem;
                    }
                }

                &--photos {
                    display: flex;

                    &-woman,
                    &-man {

                        @include max-800 {
                            height: rem(80.37);
                            width: rem(80.37);
                        }

                        @include max-500 {
                            height: rem(68.72);
                            width: rem(68.72);
                        }

                        @include max-320 {
                            height: rem(33.45);
                            width: rem(33.45);
                        }
                    }


                    &-line {
                        align-self: center;
                        height: 2px;

                        @include max-800 {
                            width: rem(132);
                        }

                        @include max-500 {
                            width: rem(112.87);
                        }

                        @include max-320 {
                            width: rem(87.79);
                        }
                    }
                }
            }

            &__second, &__third,
            &__fifth {
                align-items: center;
                background-color: $white;
                border: 1.13px solid #1B5BFF;
                border-radius: 11.34px;
                color: $bold-black;
                display: flex;
                font-size: 18px;
                height: rem(91);
                justify-content: center;
                letter-spacing: 0em;
                line-height: 29px;
                position: relative;
                text-align: center;
                width: rem(265);
                z-index: 1;

                @include max-800 {
                    font-size: 16px;
                    height: rem(67);
                    width: rem(175);
                }

                @include max-500 {
                    font-size: 10px;
                    height: rem(60);
                    width: rem(160);
                }

                @include min-320-max-380 {
                    width: rem(125);
                }

                @include max-320 {
                    font-size: 8px;
                    height: rem(46.25);
                    width: rem(99.4);
                }
            }

            &__third {
                align-self: flex-start;

                hr {
                    position: absolute;
                    right: -84px;
                    width: 82px;
                    border-top: 2px dashed #1B5BFF;

                    @include max-500 {
                        right: -22px;
                        width: rem(19.29);
                    }

                    @include max-320 {
                        right: -13px;
                        width: rem(10.64);
                    }
                }
            }

            &__second, &__fifth {
                align-self: flex-end;

                hr {
                    position: absolute;
                    left: -84px;
                    width: 82px;
                    border-top: 2px dashed #1B5BFF;

                    @include max-800 {
                        left: -81px;
                        width: 78px;
                    }

                    @include max-500 {
                        left: -22px;
                        width: rem(19.29);
                    }

                    @include max-320 {
                        left: -13px;
                        width: rem(10.64);
                    }
                }
            }

            &__ellipse7 {

                &-left {
                    position: absolute;
                    right: -95px;
                    z-index: 1;

                    @include max-800 {
                        right: -86px;
                    }

                    @include max-500 {
                        right: -32px;
                    }

                    @include min-320-max-380 {
                        right: -46px;
                    }

                    @include max-320 {
                        right: -22px;
                        width: 18px;
                    }
                }

                &-right {
                    position: absolute;
                    left: -95px;
                    z-index: 1;

                    @include max-800 {
                        left: -86px;
                    }

                    @include max-500 {
                        left: -32px;
                    }

                    @include min-320-max-380 {
                        left: -46px;
                    }

                    @include max-320 {
                        left: -22px;
                        width: 18px;
                    }
                }
            }

            &__ellipse8 {

                &-left {
                    position: absolute;
                    left: -2rem;
                    top: -2rem;
                    z-index: 1;

                    @include max-800 {
                        left: -1rem;
                        top: -1rem;
                        width: rem(35.67);
                    }

                    @include max-500 {
                        left: -1rem;
                        top: -1rem;
                        width: rem(41.61);
                    }

                    @include min-320-max-380 {
                        left: -0.5rem;
                        top: -0.5rem;
                        width: rem(30);
                    }


                    @include max-320 {
                        left: -0.5rem;
                        top: -0.5rem;
                        width: 1.5rem;
                    }
                }

                &-right {
                    position: absolute;
                    right: -2rem;
                    top: -2rem;
                    z-index: 1;

                    @include max-800 {
                        right: -1rem;
                        top: -1rem;
                        width: rem(35.67);
                    }

                    @include max-500 {
                        right: -1rem;
                        top: -1rem;
                        width: rem(41.61);
                    }

                    @include min-320-max-380 {
                        right: -0.5rem;
                        top: -0.5rem;
                        width: rem(30);
                    }

                    @include max-320 {
                        right: -0.5rem;
                        top: -0.5rem;
                        width: 1.5rem;
                    }
                }
            }

            &__snakeline {
                position: absolute;
                bottom: -35rem;
                right: 21.75rem;

                @include max-800 {
                    display: none;
                }
            }
        }

        &--bottom {
            display: flex;
            flex-direction: column;
            margin-top: 8rem;

            &-heading {
                display: flex;
                justify-content: center;
                margin-top: rem(105);

                @include max-800 {
                    flex-direction: column-reverse;
                }

                h1 {
                    align-self: center;
                    padding-left: 6rem;

                    @include max-800 {
                        padding-left: 0;
                    }

                    @include max-320 {
                        margin-top: rem(43.25);
                    }
                }

                img {
                    width: 525px;

                    @include max-800 {
                        align-self: center;
                        width: 350px;
                    }

                    @include max-500 {
                        width: 300px;
                    }

                    @include max-320 {
                        width: 250px;
                    }
                }
            }

            &-button {
                align-self: center;
                display: flex;
                justify-content: center;
                height: 60px;
                padding: 0;
                position: relative;
                text-align: center;
                width: 215px;
                z-index: 1;
            }
        }

        &__advantages {
            align-self: center;
            background-color: $brand-darker;
            border-radius: 11.34px;
            display: flex;
            height: 112px;
            margin: rem(59) 0;
            padding: 0 rem(36);
            position: relative;
            z-index: 1;

            @include max-800 {
                flex-direction: column;
                height: rem(295);
            }

            @include max-500 {
                display: block;
                margin: rem(34) 0 0 0;
                padding: 0;
            }

            &-checklist {
                align-items: center;
                background-color: #F1CA00;
                border-radius: 50%;
                color: $bold-black;
                display: flex;
                height: 39px;
                justify-content: center;
                margin-right: rem(17);
                width: 39px;
            }

            &--first {
                @include max-800 {
                    margin-top: rem(48);
                    margin-bottom: rem(26);
                }

            }

            &--second {
                @include max-800 {
                    margin-bottom: rem(31);
                }

            }

            &--third {
                @include max-800 {
                    margin-bottom: rem(40);
                }

            }

            &--first,
            &--second {
                margin-right: rem(83);
            }

            &--first,
            &--second,
            &--third {
                align-items: center;
                display: flex;

                span {
                    color: $white;
                    font-size: 18px;
                    margin: 0;

                    @include max-500 {
                        font-size: 12px;
                    }
                }

                @include max-500 {
                    margin: rem(48) rem(25);
                }
            }
        }


    }

    &__fourth-section {
        background-color: $light-blue;
        display: flex;
        padding: rem(116) 0 rem(146) rem(168);

        @include max-1050 {
            padding-left: 3.5rem;
        }

        &--left {
            font-size: 20px;
            line-height: 50px;
            letter-spacing: 0em;
            text-align: left;
            position: relative;
            width: 40%;

            @include max-1400 {
                font-size: 18px;
                line-height: 40px;
            }

            @include max-1280 {
                font-size: 14px;
                line-height: 30px;
            }

            @include max-930 {
                font-size: 12px;
                line-height: 24px;
            }

            hr {
                border: 0.5px solid #00BBF1;
                margin: 0 0 rem(31);
            }

            ul {
                list-style: none;
            }

            li {
                margin-bottom: rem(31);
            }

            &-selected {
                color: #00BBF1;
                font-weight: 700;
                margin-bottom: 0 !important;
            }
        }

        &--right {
            width: 60%;

            img {
                border: 1.16px solid #00BBF1;
                border-radius: 23.64px;
                width: 85%;
                height: 100%;
            }

            &__videoConference {
                width: 90% !important;
            }
        }

        @include max-800 {
            display: none;
        }
    }

    &__fifth-section {
        background-color: $yellow;
        padding: rem(103) 0 rem(77) 0;

        h1 {
            font-size: 50px;
            font-weight: 500;
            line-height: 50px;
            letter-spacing: 0em;
            text-align: center;

            @include max-800 {
                font-size: 30px;
            }
        }

        p {
            font-size: 20px;
            line-height: 50px;
            letter-spacing: 0em;
            text-align: center;

            @include max-800 {
                font-size: 15px;
            }
        }

        &--button {
            background-color: $white;
            display: flex;
            justify-content: center;
            height: 60px;
            margin: 0 auto;
            padding: 0;
            position: relative;
            text-align: center;
            width: 215px;
            z-index: 1;
        }
    }


}